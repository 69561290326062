var lazyLoadInstance = new LazyLoad({
    // Your custom settings go here
});

import "./partials/Header"
import "./partials/Accordion"
import "./partials/Product"
import "./partials/Popup"

jQuery(function () {
	$.fancybox.defaults.parentEl = "form"
});
