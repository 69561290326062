function collapseComponent() {
    $(".accordion-item").each(function () {
        let _this = $(this);
        let otherList = _this.siblings();
        let bodyItem = _this.find(".accordion-body");
        let otherBody = $(otherList).find(".accordion-body")

        _this.on("click", function () {
            $(bodyItem).slideToggle();
            $(otherBody).slideUp();
            _this.toggleClass("active");
            $(otherList).removeClass("active");
        });
    });
	$(".accordion-item").eq(0).trigger("click")
	$(".product-featured-item-attr .accordion-item").eq(0).trigger("click")
}

collapseComponent()