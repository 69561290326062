const productSliderInit = () => {
	$(".product-slider").each(function (index) {
		var $this = $(this);
		if ($(this).data("slides") !== undefined) {
			var initSlide = $(this).data("slides");
		} else {
			var initSlide = 4;
		}
		$this.addClass("product-slider-" + index);
		$this.find(".swiper-prev").addClass("product-slider-prev-" + index);
		$this.find(".swiper-next").addClass("product-slider-next-" + index);
		$this
			.find(".swiper-pagination")
			.addClass("product-pagination-" + index);

		var productSlider = new Swiper(
			".product-slider-" + index + " .swiper-container",
			{
				slidesPerView: initSlide,
				spaceBetween: 32,
				navigation: {
					prevEl: ".product-slider-prev-" + index,
					nextEl: ".product-slider-next-" + index,
				},
				breakpoints: {
					1024: {
						slidesPerView: 3.5,
						spaceBetween: 16,
						freeMode: true,
					},
					768: {
						slidesPerView: 2.5,
						spaceBetween: 16,
						freeMode: true,
					},
					576: {
						slidesPerView: 1.5,
						spaceBetween: 16,
						freeMode: true,
					},
				},
			}
		);
	});
};

productSliderInit();

$(".home-cate-product").each(function () {
	let $this = $(this);
	let tabs = $(this).find(".tabs");
	let tabContainer = $(this).find(".tab-container");
	$(tabs).tabslet({
		container: $(tabContainer),
	});
});
